import { render, staticRenderFns } from "./VRadioInput.vue?vue&type=template&id=36428f5a&scoped=true"
import script from "./VRadioInput.vue?vue&type=script&lang=ts"
export * from "./VRadioInput.vue?vue&type=script&lang=ts"
import style0 from "./VRadioInput.vue?vue&type=style&index=0&id=36428f5a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36428f5a",
  null
  
)

export default component.exports