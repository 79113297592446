import { render, staticRenderFns } from "./VToggle.vue?vue&type=template&id=6a3383ac&scoped=true"
import script from "./VToggle.vue?vue&type=script&lang=ts"
export * from "./VToggle.vue?vue&type=script&lang=ts"
import style0 from "./VToggle.vue?vue&type=style&index=0&id=6a3383ac&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a3383ac",
  null
  
)

export default component.exports